const dev = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "actavida-api-dev-attachmentsbucket-cke01dle1uz5"
  },
  apiGateway: {
    REGION: "us-west-2",
    NAME: "dev-actavida-api",
    URL: "https://5jll94cuj8.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_YaowtQtWo",
    APP_CLIENT_ID: "1smt2je28skgic0i6cv2ag85rt",
    IDENTITY_POOL_ID: "us-west-2:1b2f593e-efda-46f1-84dc-da6f0f411877"
  },
  STRIPE_KEY: "pk_test_LrhA3hNC7T5wmx6t6VEyxybb",
};

const prod = {
  s3: {
    REGION: "us-west-2",
    BUCKET: "actavida-api-prod-attachmentsbucket-z2o3phzg8umf"
  },
  apiGateway: {
    REGION: "us-west-2",
    NAME: "prod-actavida-api",
    URL: "https://djwfug4q08.execute-api.us-west-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_554PhdZoi",
    APP_CLIENT_ID: "6rs1i6t7svr31hd5nrp3o5t6n2",
    IDENTITY_POOL_ID: "us-west-2:e1376d03-19ae-454c-83d0-3a8926868ceb"
  },
  STRIPE_KEY: "pk_test_LrhA3hNC7T5wmx6t6VEyxybb",
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};